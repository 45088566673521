.canteenDetails {
    .canteenDetails-nav {
        width: 1200px;
        margin: 5px auto;
    }

    .canteenDetails-heart {
        width: 1200px;
        margin: 0 auto;
        padding: 20px;

        .canteenDetails-title {

            display: flex;
            justify-content: space-between;
            align-items: center;    

            .canteenDetails-left {
                // float: left;

                .name {
                    font-size: 32px;
                    color: #3F3739;
                }

                .city {
                    font-size: 14px;
                    color: #999999;
                }
            }

            .canteenDetails-right {
                // float: right;

                .cost {
                    font-size: 12px;
                    color: #3F3739;
                    margin-right: 10px;
                }

                .canteenDetails-btn {
                    width: 160px;
                    height: 48px;
                    font-size: 18px;
                    border-radius: 24px;
                }
            }
        }
    }





}
@primary-color: #C59D60;