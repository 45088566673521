.notice {
    .notice-title {
        width: 1200px;
        margin: 5px auto;
    }

    .notice-picture {
        width: 100%;
        height: 320px;
        background-color: #eee;
    }

    .notice-content {
        width: 1200px;
        margin: 0 auto;
        margin-top: 40px;

        //h3 {
        //    font-size: 20px;
        //    color: #3F3739;
        //    margin: 40px 0 25px 0;
        //}
        //
        //p {
        //    font-size: 14px;
        //    color: #666666;
        //}
    }
}
@primary-color: #C59D60;