.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f6f6f6;
  padding-top: 72px;



  .login-container {
    box-sizing: border-box;
    width: 490px;
    height: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 6px 6px 20px 0px rgba(0, 0, 0, 0.051);
    padding: 64px 0 62px 0;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 16px;

    .left-box {
      flex: 157;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      .scan-code-login {
        font-size: 24px;
        margin-top: 35px;
      }

      .hint {
        font-size: 14px;
        color: #999999;
        margin: 7px 0 20px 0;
      }

      .qrcode {
        width: 200px;
        height: 200px;
        background-color: #f6f6f6;
      }
    }

    .right-box {
      flex: 227;
      padding: 0 39px 0 55px;

      .login-header {
        text-align: center;
        display: block;
        margin: 0 auto 40px;
        font-size: 24px;
        // font-weight: 600;
        color: #000000;
      }


    }

    .login-checkbox-label {
      color: #999999;
    }

    .login-form-forgot {
      float: right;
      color: #333333;
      cursor: pointer;
    }

    .login-form-remember {
      float: left;
    }

    .login-form-button {
      height: 48px;
    }

    .registerAccount {
      color: #C59D60;
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
      cursor: pointer;
    }
    .registerAccount-right {
      color: #999999;
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
      cursor: pointer;
    }
    .login-bottom-item{
      display: flex;
      justify-content: space-between;
    }
    .login-icon{
      width: 24px;
      margin-left: 10px;
    }
    .ant-form-item-control-input{
      min-height: 24px;
    }
    .ant-form-item{
      margin-bottom: 20px;
    }
  }


}
@primary-color: #C59D60;