.canteen {
    .canteen-heart {
        width: 1200px;
        margin: 0 auto;

        .select {
            padding: 22px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin-top: 40px;
            width: 1200px;

            span {
                font-size: 20px;
            }


            .city {
                //margin-top: 8px;
                margin-bottom: 12px;
                display: flex;
                //align-items: center;
                flex-direction: row;

                .city-head{
                    display: block;
                    font-size:22px;
                    margin-right:40px;
                    min-width: 44px;
                }

                .city-name{

                    .city-item{
                        cursor: pointer;
                        display: inline-block;
                        padding: 5px 20px;
                        border: 1px solid #999;
                        border-radius: 20px;
                        color: #666;
                        margin-right: 20px;
                        margin-bottom: 8px;
                    }

                    .selected1 {
                        background-color: #C59D60;
                        color: #fff;
                        border: 1px solid #C59D60;
                    }
                }
            }
            .type {
                display: flex;
                //align-items: center;
                flex-direction: row;



                .type-head {
                    display: block;
                    font-size:22px;
                    margin-right:40px;
                    min-width: 44px;
                }


                .type-name {

                    .type-item {
                        cursor: pointer;
                        display: inline-block;
                        padding: 5px 20px;
                        border: 1px solid #999;
                        border-radius: 20px;
                        color: #666;
                        margin-right: 20px;
                        margin-bottom: 8px;
                    }

                    .selected1 {
                        background-color: #C59D60;
                        color: #fff;
                        border: 1px solid #C59D60;
                    }
                }
            }
        }

        .type-rcmdlist {
            padding: 22px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin-top: 20px;

            .rcmdlist-title {
                font-size: 20px;
                color: #3F3739;
            }

            .rcmdlist-item {


                .box {
                    display: flex;
                    padding: 20px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    .picture {
                        cursor: pointer;
                        width: 200px;
                        height: 112px;
                        background-color: #999;
                        border-radius: 4px;
                    }

                    .box-right {
                        flex: 1;
                        margin-left: 20px;

                        .name {
                            font-size: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }

                        .city {
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            color: #999;
                            margin: 6px 0 8px;

                            .city-right {
                                display: flex;
                                align-items: center;
                                margin-left: 25px;

                                .icon-kehudizhi {
                                    margin-right: 5px;
                                }
                            }
                        }

                        .describe {
                            font-size: 14px;
                            color: #666666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }

            .canteen-pagination {
                margin: 40px 0 10px;
                text-align: right;
            }
        }
    }
}
.type-rcmdlist .main-pagination {
    margin-top: 40px;
}
//.city-name.type-name{
//    display: flex;
//    flex-direction: row;
//}
//.select-all-item{
//    display: flex;
//    flex-direction: row;
//    min-width: 120px;
//    height:42px;
//    background-color: #fff;
//    justify-content: center;
//    align-items: center;
//}
@primary-color: #C59D60;