.sub-order-form{
  display: flex;
  flex-direction: row;
  width:1200px;
  justify-content: space-between;
  .date-box{
    width: 614px;
  }
}
.form-box{
  //height: 500px;
  border:1px solid #F6F6F6;
  width: 614px;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}


.multipleDatePicker {
  :global {
    .ant-picker-input {
      display: none !important;
    }
    .ant-picker {
      border: none;
      padding: 0;
    }
  }
}
.ant-picker{
  //display: none !important;
  opacity: 0;
}
.sub-order-content-box{
  width: 1200px;
  min-height: 599px;
  margin: 0 auto;
  justify-content: space-between;
}

.sub-order-from-store{
  display: flex;
  justify-content: left;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 46px;
  line-height: 46px;
  font-size: 32px;
  padding-right: 40px;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;

}
.sub-order-from-store-name{
  display: inline-block;
  height: 46px;
  line-height: 46px;
  max-width: 1000px;
  white-space: nowrap;
  font-size: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.ant-picker-panel-container{
  box-shadow: none !important;
}
.date-box{
  margin-top: 20px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 520px !important;
  height: 422px !important;
}
.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel{
  width: 520px !important;

}
.form-box-content{
  width: 534px;
  padding-top: 18px;
}
.sub-order-form-fee{
  font-size: 20px;
}
.sub-fee-calculate-item{
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin-top: 8px;
}
.sub-fee-calculate-item-left{
  width: 95px;

}

.grey-paragraph{
  width: 534px;
  margin-top: 19px;
  margin-bottom: 32px;
  background-color: #f6f6f6;
  border-radius: 4px;
  padding: 20px;
}
.date-box .ant-picker-dropdown{
  z-index:10
}
.package-box{

}
.ant-picker-cell::before{
  content:none !important;
}
@primary-color: #C59D60;