.search {
    .search-title {
        width: 1200px;
        margin: 5px auto;
    }

    .search-picture {
        width: 100%;
        height: 320px;
        background-color: #eee;
    }

    .search-main {
        width: 1200px;
        margin: 0 auto;


        h3 {
            font-size: 18px;
            color: #3F3739;
            margin: 40px 0 20px 0;
        }

        .search-content {
            display: flex;
            flex-wrap: wrap;

            .box {
                padding: 0 15px;
                margin-bottom: 40px;

                .picture {
                    width: 273px;
                    height: 155px;
                    background-color: #b8b3b3;
                    border-radius: 4px;
                    margin-left: -15px;
                    margin-right: 10px;
                    cursor: pointer;
                }

                .name {
                    font-size: 16px;
                    margin-left: -15px;

                }

                .city {
                    color: #747474;
                    margin-left: -15px;

                }
            }

            .no-result {
                width: 280px;
                margin: auto;

                .no-resule-img {
                    width: 100%;

                    .text {
                        font-size: 14px;
                        color: #999999;
                        text-align: center;
                    }
                }
            }
        }


    }
}
@primary-color: #C59D60;