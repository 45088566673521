.order-commit-content{

  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.order-commit-info-content{
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.051);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid rgba(0,0,0,0.098);
  width: 1200px;
  .commit-head{
    height: 48px;
    line-height: 48px;
    padding-left: 21px;
    border: 1px solid #F6F6F6;
    font-size: 16px;
  }
  .commit-item{
    display: flex;
    height: 45px;
    line-height: 45px;
    padding-left: 21px;
    flex-direction: row;
    color: #666666;
  }
}
.order-commit-info-content-no-shadow{
  margin-top: 35px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid rgba(0,0,0,0.098);
}
.commit-notice{
  color: #EA0025;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-top: 10px;
}
.commit-desc-head{

  height: 48px;
  line-height: 48px;
  padding-left: 21px;
  border: 1px solid #F6F6F6;
  border-radius: 8px 8px 0 0;
  background-color: #f6f6f6;
  font-size: 16px;

}
.commit-desc-item{
  display: flex;
  flex-direction: row;
  height: 45px;
  line-height: 45px;
  padding-left: 21px;
  border-top:1px solid #F6F6F6 ;
  color: #666666;

}
.commit-item-label{
  width: 100px;
  color: #666666;
}
.commit-sub-price-desc-content{

}
.font-weight-bold{
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}
.commit-agreement{
  width: 1200px;
  background-color:rgba(234,0,37,0.102) ;
}
.commit-agreement-font{
  cursor: pointer;
  color:#EA0025;
}
.commit-agreement-section> .ant-checkbox-checked .ant-checkbox-inner{
  background-color: #EA0025 ;
  border-color: #EA0025 ;
}
@primary-color: #C59D60;