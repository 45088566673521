@font-face {
  font-family: "iconfont";
  /* Project id 4347253 */
  src: url('iconfont.woff2?t=1701054149146') format('woff2'),
    url('iconfont.woff?t=1701054149146') format('woff'),
    url('iconfont.ttf?t=1701054149146') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-kehudizhi:before {
  content: "\e6b8";
  color: #999999;
}