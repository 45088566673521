.nav-header {
    position: fixed !important;
    top: 0 !important;
    z-index: 100 !important;
    width: 100% !important;
    height: 72px !important;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    justify-content: center;
}

.nav-heart {
    min-width: 1920px;
    height: 100%;
    //margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-left {
        margin-left: 360px;
        display: flex;
        align-items: center;

        .logo {
            width: 40px;
            //height: 40px;
            //background-color: #BBBBBB;
        }

        .nav-tabs {
            display: flex;
            align-items: center;

            .router-main {
                display: inline-block;
                width: 72px;
                text-align: center;
                cursor: pointer;
                height: 30px;
                line-height: 30px;
                margin-left: 40px;
                margin-right: 20px;
                color: #333;

            }

            .router-canteen {
                display: inline-block;
                width: 72px;
                text-align: center;
                cursor: pointer;
                height: 30px;
                line-height: 30px;
                color: #333;

            }

            .selected {
                color: #C59D60;
                border-bottom: 2px solid #C59D60;
            }
        }
    }

    .nav-right {
        margin-right: 360px;
        display: flex;
        align-items: center;

        .search-input {
            border-radius: 20px;
            width: 320px;
            height: 40px;
            border: 1px solid #C59D60;

            .ant-input.ant-input-lg {
                font-size: 14px;
            }
        }

        .service {
            width: 20px;
            height: 20px;
            margin: 0 26px;
            border-radius: 8px;

            img {
                max-width: 100%;
                max-height: 100%;
                display: block; // 防止图片底部留有空白
                cursor: pointer;
            }
        }
        .consult{
            width: 20px;
            height: 20px;
            margin: 0 0 0  20px;
            line-height: 20px;
            border-radius: 8px;
            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                cursor: pointer;
            }
        }

        .user {
            max-height: 72px;
            cursor: pointer;

            .user-item {
                display: flex;
                align-items: center;
                height: 72px;

                .head-portrait {
                    width: 32px;
                    height: 32px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        display: block; // 防止图片底部留有空白
                    }
                }

                .username {
                    color: #333;
                    margin-left: 5px;
                    font-size: 16px;
                }
            }
        }
    }
}

.dropdown-item {
    width: 200px;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;

    .img {
        display: inline-block;
        width: 25px;
    }

    .name {
        vertical-align: middle;
        margin-left: 5px;
        font-size: 14px;
    }
}

.nav-servicer {
    width: 320px;
    text-align: center;
    border-radius: 8px;

    .qrcode {
        width: 160px;
        height: 160px;
        background-color: #eee;
        margin: 0 auto;
        margin-top: 30px;

    }

    .wxhint {
        margin: 20px 0 10px;
    }
}

.ant-popover-inner {
    padding: 10px 20px;

    .ant-popover-title {
        padding: 5px 16px 4px 0;
        font-size: 16px;
        color: #3F3739;
    }
}

.avatar{
    border-radius: 50%;
}
.global-relative-box{
    //position: relative;
    //z-index: 999;
    //width: 1200px;
    ////background-color: red;
    //height: 1px;
    //margin: 0 auto;
}
.search-history-box{
    //position: relative;
    //z-index: 9999;
    //top:1px;
    //left: 680px;
    width: 250px;
    height: 300px;
    background-color: #fff;
}
.search-history-box-item-head{
    display: flex;
    justify-content: space-between;
    font-size:12px;
}
.search-history-list-item{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    //padding-left: 20px;
    margin-bottom: 16px;
    font-size: 12px;
    color: #999999;
}
.search-history-item-text{
    width: 100px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
}
.search-history-list-item-icon{
    margin-right: 8px;
}
.search-history-box-item-head-left{
    //margin-top: 22px;
    //margin-left: 21px;
    height: 12px;
    //font-weight: bold;
    font-size: 16px;

}
.search-history-box-item-head-right{
    cursor: pointer;
    //margin-top: 22px;
    //margin-right: 21px;
    height: 12px;
    color: #999999;

}
.search-history-box-content{
    margin-top: 25px;
    height: 250px;
    overflow-y:scroll;
}
.search-history-box-content::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0); /* 设置滚动条滑块的背景色，透明度为 0 */
}
.ant-popover-inner{
    border-radius: 8px !important;
}
.ant-form-item-explain-error {
    text-align: left;
}
//.ant-modal-body{
//    padding-top: 0 !important;
//    padding-bottom: 0 !important;
//}

//防止模态框弹出导致滚动条样式变化
html body {
    width: 100% !important;
    overflow-y: auto !important;
}
@primary-color: #C59D60;