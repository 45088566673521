.content-detail {
  width: 100%;
  .detail-box {
    // width: 1200px;
    margin: 0 auto;
    .detail-title {
      width: 1200px;
      height: 10%;
      margin: 0 auto;
    }
    //上半部分
    .detail-container-header {

      width: 1200px;
      margin: 0 auto;
      //   background-color: #f6f6f6;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .header-left {
        font-size: 32px;
        font-family: PingFang SC Bold, PingFang SC Bold;
        font-weight: 400;
        color: #3f3739;
        max-width: 800px;
        overflow: auto;
      }
      .header-right {
        display: flex;
        .right-left {
          font-size: 12px;
          font-family: PingFang SC Regular, PingFang SC Regular;
          font-weight: 400;
          color: #3f3739;
          margin-right: 10px;
          line-height: 48px;
        }
        .right-right {
          cursor: pointer;
          width: 160px;
          height: 48px;
          background: #c59d60;
          box-shadow: 0px 0px 0px 0px rgba(34, 36, 38, 0.149);
          border-radius: 24px 24px 24px 24px;
          opacity: 1;
          font-size: 18px;
          font-family: PingFang SC Regular, PingFang SC Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
        }
        .right-right-cannot {
          cursor: pointer;
          width: 160px;
          height: 48px;
          background: #BBBBBB;
          box-shadow: 0px 0px 0px 0px rgba(34, 36, 38, 0.149);
          border-radius: 24px 24px 24px 24px;
          opacity: 1;
          font-size: 18px;
          font-family: PingFang SC Regular, PingFang SC Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
        }
      }
    }
    .header-text {
      width: 1200px;
      margin: 0 auto;
      font-size: 12px;
      font-family: PingFang SC Regular, PingFang SC Regular;
      font-weight: 400;
      color: #999999;
      //margin-top: -4px;
      margin-bottom: 20px;
    }
    .header-img {
      width: 1200px;
      height: 600px;
      margin: 0 auto;
      background: #999;
    }
    .divider {
      width: 100%;
      height: 20px;
      background: #f6f6f6;
    }
    .content-box {
      width: 1200px;
      margin: 0 auto;
      img{
        //富文本内容img最大宽度
        max-width: 1200px;
      }
      .content-tabs {
        width: 100%;
        border-bottom: 2px solid rgba(0,0,0,0.098);
        .ant-anchor-wrapper {
          margin-left: -3.5%;
          .ant-anchor {
            display: flex;
            .ant-anchor-ink {
              &::before {
                width: 0 !important;
              }
            }
            .ant-anchor-link {
              margin-right: 50px !important;
              font-size: 20px;
            }
            .ant-anchor-link-active {
              .ant-anchor-link-title-active {
                border-bottom: 2px solid #c59d60;
              }
            }
          }
        }
      }
      .canting {

        margin: 0 auto;
        .canting-title {

          font-size: 20px;
          font-family: PingFang SC Bold, PingFang SC Bold;
          font-weight: 400;
          color: #3f3739;
          padding: 30px 0;
        }
        .canting-content{
          color:#666666;
        }
      }
    }
  }
}
.cancel-rule-desc-item{
  margin-top: 5px;
  color:#666666;
}
.prize-member-content{
  border:1px solid rgba(0,0,0,0.098);
  border-radius: 4px;
  border-bottom: none;
  margin-top: 30px;
}
.prize-member-item{
  display: flex;
  flex-direction: row;
  border-bottom:1px solid rgba(0,0,0,0.098);
  height: 39px;
  align-items: center;
}
.prize-member-item-name{
  padding-left:20px;
  width:148px ;
}
.content-box-detail{
  background: #FFFFFF;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.0314);
  border-radius: 8px 8px 8px 8px;
  padding: 20px;
}
.detail-tabs{
  position: sticky;
  top: 72px;

}
.detail-tabs .ant-tabs-nav-list{
  background-color: #fff;
}
.detail-tabs .ant-anchor-link-title {
  &:hover {
    border-bottom: 1px solid #C59D60; /* 设置底部下划线的颜色和宽度 */
  }

}
.detail-tabs .ant-tabs-tab-btn{
  font-size: 16px;

}

.detail-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 20px;
}
.detail-tabs .ant-tabs-nav-wrap{
  background-color: #fff;
}
@primary-color: #C59D60;