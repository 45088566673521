@antd-border-radius-large: 8px;
.userinfo-from{
  width: 940px;
  background-color: #fff;
  height: 566px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.userinfo-title{
  margin:20px;
  font-size: 20px;
}
.userinfo-img{
  width:80px;
  height:80px;
  margin:20px;
}
.userinfo-item-box{
  margin-left:20px;
}
.userinfo-label{
  margin-top:20px;
  margin-bottom:10px;
}
.userinfo-modify-pwd-model >.ant-modal-header{
  border-radius: 8px;
}

.forgetPassword {

}
.text-center{
  margin-left:26px;
  margin-right:26px;
}
.modify-pwd-hint   {
  color: #EA0025;
  font-size: 14px;
  margin-bottom: 40px;
}
.ant-modal-content,.ant-modal-header{
  border-radius: @antd-border-radius-large !important;
}

@primary-color: #C59D60;