.footer-heart {
    width: 1200px;
    max-height: 300px;
    margin: 0 auto;

    .top {
        padding: 36px 0 30px 0;
        display: flex;
        text-align: left;
        justify-content: space-between;
        height: 210px;

        // max-height: 234px;
        .column1 {

            .brand {
                width: 40px;
                height: 40px;
                //background: #BBBBBB;
                cursor: pointer;
            }
        }

        .column2 {
            min-width: 80px;

            .title {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                margin-bottom: 25px;
                cursor: pointer;
            }

            .every {
                font-size: 12px;
                color: #666666;
                margin-bottom: 15px;
                cursor: pointer;
                a{
                    color: #666666;
                }
            }
        }
    }

    .bottom {
        padding-top: 20px;
        border-top: solid 1px rgba(0,0,0,0.098);
        color:#999999;
    }
}
.home .ant-layout-footer{
    background-color: #f6f6f6;
}
@primary-color: #C59D60;