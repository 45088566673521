.content-box{
  width: 940px;
  background: #FFFFFF;
  box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.0314);
  border-radius: 8px 8px 8px 8px;
  padding: 20px;

}
.content-title {
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(63, 55, 57, 1);
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.list-title{
  width: 900px;
  height: 52px;
  background: #F6F6F6;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.list-content{
  width: 940px;

  margin-top: 20px;
}

.list-content-head{
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  width: 900px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px 4px 0px 0px;
  background: #F6F6F6;
}
.list-content-body{
  width: 900px;
  height: 108px;
}

.row-flex{
  display: flex;
  flex-direction: row;
}
.order-head{
  border-radius: 4px;
  .order-rest-head{
    padding-left: 20px;
    width: 440px;
    height: 52px;
    line-height: 52px;
  }
  .order-item-head{
    width: 140px;
    height: 52px;
    text-align: center;
    line-height: 50px;
  }
}
.order-content{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.order-content-item-body{
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  border: 1px solid #F6F6F6;
}
.store-cover{
  width: 120px;
  min-width: 120px;
  height: 68px;
  border-radius: 4px;
  margin:0 10px 0 20px;
  overflow: hidden;
}
.inline{
  display: inline-block;
}
.item-head{
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: center;
  width: 440px;

}
.item-other{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-left: 1px solid #F6F6F6;
  border-bottom: none;
}
.item-sub-time{
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 12px;
}
.list-content-item-box{
  margin-top:20px;
}
.pagination{
  width: 900px;
  margin-top: 50px;
  display: flex;
  flex-direction: row-reverse;
}
.order-to-paid{
  color: #ea0025;
}
.order-to-confirm{
  color: #3D88FB;
}
.order-is-confirmed{
  color:#12CC7E;
}
.order-to-refund{
  color: #ea0025;
}
.order-canceled{
  color: #999999;
}
.order-done{
  color: #c59d60;
}
.ant-modal-footer {
  display: flex;
  justify-content: center;
  border-top:none !important;
  button {

  }
}
.text-center{
  text-align: center;
}


.detail-status{
  margin-top: 27px;
  font-size: 32px;
}
.detail-header{
  padding-left: 20px;
  width: 900px;
  height: 48px;
  line-height: 48px;
  border-radius: 4px 4px 0px 0px;
  background: #F6F6F6;
  font-weight: normal;
}
.detail-item{
  border:1px solid #F6F6F6;
  border-top-style:none;
  min-height:48px;
  line-height:48px;
  display: flex;
}
.between-slider{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-item-left{
  display: inline-block;
  width:120px;
  padding-left: 22px;
}
.detail-item-right{
  display: inline-block;
  width:760px;
}
.detail-sub-price-desc-title{
  margin-top: 10px;
  margin-bottom: 10px;
}

.grey-font{
  color : #666666;
}
.pay-icon{
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.pay-icon-box{
  margin-left: 12px;
  margin-top:9px;
  width: 328px;
  height: 48px;
  background-color: rgba(39,111,255,0.05);
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
.pay-btn-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
}
.pay-btn-box.choose{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
.order-detail-store-name{
  color:#3f3739;
  font-size: 20px;
  margin-top: 40px;
}
.order-status-desc{
  font-size: 12px;
  color:#999999;
}
.title-space{
  margin-left: 10px;
}

.commit-notice {
  color: #EA0025;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-top: 10px;
}
@primary-color: #C59D60;