.userInfo-background {
  width: 1200px;
  margin: 0 auto;
  margin-top:30px;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  .userInfo-menu{
    height:566px;
    width: 240px;
    min-width: 240px;
    background-color: #FFF;
    border-radius: 8px;
    overflow: hidden;
  }
  .userInfo-content{
    margin-left: 30px;
    border-radius: 8px;
  }
}
.userInfo-menu .ant-menu-sub.ant-menu-inline{
  background-color: #fff;
}
@primary-color: #C59D60;