.forgetPassword {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #f6f6f6;
    padding-top: 72px;

    .forgetPassword-container {
        width: 490px;
        height: 582px;
        background-color: #fff;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 16px;
        padding: 64px;

        .forgetPassword-header {
            text-align: center;
            display: block;
            margin: 0 auto 30px;
            font-size: 24px;
            // font-weight: 600;
        }

        .sender-code {
            font-size: 14px;
            border-radius: 4px;
            width: 100%;
        }

        .forgetPassword-checkbox-label {
            font-size: 14px;
            color: #999999;

            .forgetPassword-agreement {
                color: #c59d60;
            }
        }

        .forgetPassword-hint {
            color: #EA0025;
            font-size: 14px;
            margin-bottom: 40px;


        }
    }
}
@primary-color: #C59D60;