.home {


  .content {
    padding-top: 72px;
    padding-bottom: 72px;
    min-height: calc(100vh - 300px);
  }
  .content-grey {
    width: 100%;
    padding-top: 72px;
    padding-bottom: 72px;
    min-height: calc(100vh - 300px);
    background-color: #f0f2f5;
  }
}


@primary-color: #C59D60;