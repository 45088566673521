.main-content {
    .swiper {
        margin:0 auto;
        width: 100%;
        height: 320px;
        background-position: center;
    }

    .heart {
        width: 1200px;
        margin: 0 auto;

        .main-title {
            font-size: 18px;
            margin: 40px 0 20px 0;
        }
    }
}

.main-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:left;
    .item-position-0{
        margin-right: 36px;
    }
    .item-position-1{
        margin-right: 36px;
    }
    .item-position-2{
        margin-right: 36px;
    }
    .item-position-3{

    }
    .box {

        margin-bottom: 40px;
        margin-left: 15px;

        .picture {
            min-width: 273px;
            max-width: 273px;
            height: 155px;
            background-color: #b8b3b3;
            border-radius: 4px;
            margin-left: -15px;
            cursor: pointer;
        }

        .name {
            font-size: 16px;
            margin-left: -15px;
            max-width: 273px;
            //text-overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;

        }

        .city {
            color: #747474;
            margin-left: -15px;

        }
    }
}

.main-pagination {
    display: block;
    text-align: right;
    //margin-bottom: 60px !important;
}
.main-content .main-pagination {
    margin-top: 40px;
}
.ant-pagination-item-active {
    background-color: #C59D60 !important;

    a {
        color: #fff !important;
    }
}
.picture{
    position: relative;
}
.prize-icon{
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 54px;
    height: 24px;
    background-color: #DE474C;
    border-radius: 4px 0 4px 0;
    font-size:12px;
    color:#ffffff;
    text-align: center;
    line-height: 24px;
}
.dots-style{
    color: #000c17;
    margin-bottom: 50px;
    //background-color: #b31b1b;
}
//.ant-carousel .slick-dots li{
//    margin:0 !important;
//}
//.ant-carousel .slick-dots li.slick-active{
//    width: 40px;
//    //color: #000000;
//}
//.ant-carousel .slick-dots li button::after{
//    //background-color: #000c17;
//}
//.ant-carousel .slick-dots li button .slick-active{
//    color: #000c17 !important;
//}
.header-img .ant-carousel .slick-dots li button::after{
    top:-1px;
    bottom:-1px;
    background-color: #000;
    
}
.header-img  .ant-carousel .slick-dots-bottom {
    bottom:20px;
}
.swiper .ant-carousel .slick-dots li button::after{
    top:-1px;
    bottom:-1px;
    background-color: #000;

}
.swiper .ant-carousel .slick-dots-bottom {
    bottom:20px;
}
@primary-color: #C59D60;