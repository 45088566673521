.userAgreement {
    width: 100%;
    min-height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #f6f6f6;
    padding-top: 72px;

    .userAgreement-container {
        width: 1200px;
        min-height: calc(100vh - 72px);
        background-color: #fff;
        margin: 0 auto;
        padding: 40px 100px 0;

        .userAgreement-title {
            font-size: 20px;
            text-align: center;
            margin-bottom: 20px;
        }

        .userAgreement-content {
            padding-bottom: 40px;
        }
        img{
            max-width: 1000px;
        }
    }
}
@primary-color: #C59D60;